<template>
  <el-row class="table">
    <span class="title">班级题库</span>
    <el-row class="row-bg">
      <el-select
        v-model="seleClass"
        placeholder="请选择班级"
        class="tb-select"
        @change="handleSelectChange"
      >
        <el-option
          v-for="(item, index) in classList"
          :key="index"
          :label="item.name"
          :value="item.class_id"
        ></el-option>
      </el-select>
    </el-row>
    <el-table
      :data="dataList"
      border
      fit
      highlight-current-row
      class="tb-list"
    >
      <el-table-column label="班级题库" align="center" prop="contestName"></el-table-column>

      <el-table-column label="班级" align="center" prop="className"></el-table-column>

      <el-table-column label="上课时间" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.start_time | dateformat}}</span>
        </template>
      </el-table-column>

      <el-table-column label="操作" align="center" width="350">
        <template slot-scope="scope">
          <el-button size="small" type="primary" plain @click="workDetail(scope.row)">题库管理</el-button>
          <el-button
            size="small"
            type="primary"
            plain
            @click="workRecord(scope.row)"
            style="margin-left: 10px;"
          >提交记录</el-button>
          <el-button size="small" type="primary" plain @click="workRanking(scope.row)">提交统计</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-row>
</template>

<script>
import { getTeacherClassByUser, getTeacherClassContext } from '@/api/senate/lesson'

export default {
  filters: {
    dateformat: function (val) {
      if (val == null) {
        return ''
      }
      val = val.substring(0, 19)
      return val
    }
  },
  data () {
    return {
      seleClass: null,
      classList: [],
      dataList: [],
      class_id: ''
    }
  },
  created () {
    this.getClassList()
    this.getclassLibrary()
  },
  methods: {
    // 获取老师排课的班级
    async getClassList () {
      const res = await getTeacherClassByUser()
      this.classList = res.body
    },

    // 查询老师管理的题库列表
    async getclassLibrary () {
      const res = await getTeacherClassContext({
        class_id: this.class_id
      })
      this.dataList = res.body
    },

    // 题库管理
    workDetail (row) {
      this.$router.push({
        path: '/senate/class-quesbank-detail',
        query: {
          activeName: 'first',
          classData: JSON.stringify(row)
        }
      })
    },

    // 提交记录
    workRecord (row) {
      this.$router.push({
        path: '/senate/class-quesbank-detail',
        query: {
          activeName: 'second',
          classData: JSON.stringify(row)
        }
      })
    },

    // 提交统计
    workRanking (row) {
      this.$router.push({
        path: '/senate/class-quesbank-detail',
        query: {
          activeName: 'third',
          classData: JSON.stringify(row)
        }
      })
    },

    handleSelectChange (val) {
      this.class_id = val
      this.getclassLibrary()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../style/table.scss";
/deep/ .el-table {
  position: relative;
  width: 100%;
  max-width: 100%;
}
</style>
