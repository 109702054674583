var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c("span", { staticClass: "title" }, [_vm._v("班级题库")]),
      _c(
        "el-row",
        { staticClass: "row-bg" },
        [
          _c(
            "el-select",
            {
              staticClass: "tb-select",
              attrs: { placeholder: "请选择班级" },
              on: { change: _vm.handleSelectChange },
              model: {
                value: _vm.seleClass,
                callback: function ($$v) {
                  _vm.seleClass = $$v
                },
                expression: "seleClass",
              },
            },
            _vm._l(_vm.classList, function (item, index) {
              return _c("el-option", {
                key: index,
                attrs: { label: item.name, value: item.class_id },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticClass: "tb-list",
          attrs: {
            data: _vm.dataList,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "班级题库", align: "center", prop: "contestName" },
          }),
          _c("el-table-column", {
            attrs: { label: "班级", align: "center", prop: "className" },
          }),
          _c("el-table-column", {
            attrs: { label: "上课时间", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm._f("dateformat")(scope.row.start_time))
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center", width: "350" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary", plain: "" },
                        on: {
                          click: function ($event) {
                            return _vm.workDetail(scope.row)
                          },
                        },
                      },
                      [_vm._v("题库管理")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { size: "small", type: "primary", plain: "" },
                        on: {
                          click: function ($event) {
                            return _vm.workRecord(scope.row)
                          },
                        },
                      },
                      [_vm._v("提交记录")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary", plain: "" },
                        on: {
                          click: function ($event) {
                            return _vm.workRanking(scope.row)
                          },
                        },
                      },
                      [_vm._v("提交统计")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }